import L from 'leaflet'

export const employeeMarker = L.icon({
  iconUrl: require('./assets/reddot.svg'),
  iconSize: [12, 12],
  iconAnchor: [6, 12],
})

export const businessMarker = L.icon({
  iconUrl: require('./assets/bluedot.svg'),
  iconSize: [16, 16],
  iconAnchor: [8, 16],
})

export const createPieChartMarker = (employeesPercent: number, businessPercent: number) => {
  const size = 128
  const radius = size / 2
  const innerRadius = radius / 2
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size
  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.beginPath()
    ctx.moveTo(radius, radius)
    ctx.arc(radius, radius, radius, 0, (Math.PI * 2 * employeesPercent) / 100)
    ctx.fillStyle = '#FF5837'
    ctx.fill()

    ctx.beginPath()
    ctx.moveTo(radius, radius)
    ctx.arc(radius, radius, radius, (Math.PI * 2 * employeesPercent) / 100, Math.PI * 2)
    ctx.fillStyle = '#2257C5'
    ctx.fill()

    ctx.beginPath()
    ctx.arc(radius, radius, innerRadius, 0, Math.PI * 2)
    ctx.fillStyle = 'white'
    ctx.fill()
  }

  return L.icon({
    iconUrl: canvas.toDataURL(),
    iconSize: [size / 8, size / 8],
    iconAnchor: [radius / 8, size / 8],
  })
}

export const createPieChartMarkerFromCounts = (employeesCount: number, businessCount: number) => {
  const total = employeesCount + businessCount
  const employeesPercent = (employeesCount / total) * 100
  const businessPercent = (businessCount / total) * 100
  return createPieChartMarker(employeesPercent, businessPercent)
}

export const getTileLayersURL = (platform: string, mapBaseUrl: string) => {
  const normalizedBaseUrl =
    mapBaseUrl.startsWith('http://') || mapBaseUrl.startsWith('https://') ? mapBaseUrl : `https://${mapBaseUrl}`

  if (platform === 'prem') {
    return normalizedBaseUrl.replace(/^https?:\/\//, 'http://') + `/tile/{z}/{x}/{y}.png`
  } else {
    const baseWithoutProtocol = normalizedBaseUrl.replace(/^https?:\/\//, '')
    return `https://{s}.${baseWithoutProtocol}/{z}/{x}/{y}.png`
  }
}
